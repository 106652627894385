export default {
  COMMON_ERR1: 'Content cannot be empty',
  COMMON_ERR2: 'Mismatch between content and rules',
  COMMON_ERR3: 'Please upload content',
  COMMON_ERR4: 'Please select content',
  /*----------------登录页面------------------------*/
  site_title: 'Merchant self-service platform',
  login_username_placeholder: 'Please enter your user name',
  login_password_placeholder: 'Please enter your login password',
  login_code_placeholder: 'Please enter the verification code',
  login_presentation_start: 'Do not have an account，',
  login_presentation_end: 'register now',
  login_forgotten_password: 'Forget password',
  login_button: 'Login',
  login_success: 'Login Successful',
  exit_success: 'Exit successful',
  site_logo_title: '乐想汇',
  /*----------------登录页面结束------------------------*/
  /*----------------注册页面------------------------*/
  // 注册页面-----左边部分
  register_card_title: 'User Registration',
  register_title: 'Registration',
  register_form1: 'Country/region of business registration',
  register_form1_placeholder:
    'Please enter the country/region where your business is registered',
  register_form2: 'Mailbox',
  register_form2_placeholder: 'Please enter your e-mail address',
  register_form2_validate:
    'Please enter your e-mail address in the correct format!',
  register_form3: 'Mobile phone number',
  register_form3_placeholder: 'Please enter your cell phone number',
  register_form4: 'Password',
  register_form4_placeholder: 'Please enter your password',
  register_form4_validate:
    'Password should contain any two types of numbers and uppercase and lowercase letters',
  register_form5: 'Confirm Password',
  register_form5_placeholder: 'Please enter your password again',
  register_form5_validate: 'Inconsistency between two inputs!',
  register_form6: 'Verification code',
  register_form6_placeholder: 'Please enter the verification code',
  register_code_button: 'Get CAPTCHA',
  register_code_time: 'Resend after ',
  register_form7: 'Recommended encoding',
  register_form7_placeholder: 'Please enter the referral code.',
  register_user_agreement: 'User agreement',
  register_button:
    'Have read and agree to the above agreement and have completed registration',
  register_message1: 'Already have an account，',
  register_message2: 'Sign in now',
  // 注册页面-----右边部分
  register_sub_title1: 'City',
  register_sub1_content1:
    'Please select other if your location is not selected',
  register_sub_title2: 'Mobile phone number',
  register_sub2_content1:
    'Please check if the cell phone number is correct and if there are any outstanding charges',
  register_sub2_content2:
    'Check for spam in your SMS or blocked by third-party software',
  register_sub2_content3: 'If still not received, try resending',
  register_sub_title3: 'Mail Cautions',
  register_sub3_content1: 'Please check if the e-mail address is correct',
  register_sub3_content2: 'Check the spam box in your mailbox',
  register_sub3_content3: 'If still not received, try resending',
  register_sub_title4: 'The password must contain',
  register_sub4_content1:
    'English Beginning Include numbers, upper and lower case letters any two 8-20 characters',
  /*----------------注册页面结束------------------------*/
  /*----------------忘记密码页面------------------------*/
  forget_password_title: 'Forget password',
  forget_form1_placeholder: 'Please enter your user name',
  forget_form2_placeholder: 'Please enter the image verification code',
  forget_form3_placeholder: 'Please enter the verification code',
  forget_code_font: 'Get CAPTCHA',
  forget_last_time: 'Retrieve after ',
  forget_form4_placeholder: 'Please enter a new password',
  forget_form5_placeholder: 'Confirm Password',
  forget_rule_password1: 'Password cannot be empty',
  forget_rule_password2:
    'Please enter a 6-18 digit password that must contain both numbers and letters.',
  forget_rule_password3: 'Please enter your password again',
  forget_rule_password4: 'Inconsistency between two password entries',
  forget_button: 'Confirm',
  forget_code_message: 'Sent successfully, please check receipt',
  forget_success_message: 'Password changed successfully',
  /*----------------忘记密码页面结束------------------------*/

  /*----------------未进件页面(incoming-unit)------------------------*/
  incoming_step_title1: 'Subject data entry',
  incoming_step_title2: 'Network access and file submission review in progress',
  incoming_step_title3: 'Review result',
  incoming_title: 'Subject type',
  incoming_title_type1: 'Mainland enterprises',
  incoming_title_type2: 'Hong Kong enterprises',
  incoming_title_type3: 'Enterprises of other countries and regions',
  incoming_company_title: 'Enterprise information (required)',
  incoming_company_form1: 'Business license picture',
  incoming_company_form2: 'Pictures of corporate documents',
  incoming_company_form3: "Company's registered country/region",
  incoming_company_form4: 'Enterprise certificate picture',
  incoming_company_form5: 'Company name',
  incoming_company_form6: "Company's English name",
  incoming_company_form7: 'Unified social credit code',
  incoming_company_form8: 'Registered address',
  incoming_company_form9: 'Business address',
  incoming_company_form10: 'Establishment date',
  incoming_company_form11: 'Registered capital',
  incoming_company_form12: 'Valid period',
  incoming_company_form13: "Company's nature",
  incoming_company_form14: 'Registration certificate number',
  incoming_company_form15: 'Commercial registration certificate number',
  incoming_company_form16:
    'Valid period of commercial registration certificate',
  incoming_company_form17:
    "Company's registered address (consistent with registered address of commercial registration certificate)",
  incoming_company_form18: "Company's actual business address",
  incoming_company_form19: 'Enterprise certificate number',
  incoming_company_form20: 'Valid period of certificate',
  incoming_company_form21:
    "Company's registered address (consistent with registered address of commercial registration certificate)",
  incoming_company_form22: "Company's actual business address",
  incoming_company_form23: "Company's business scope",
  incoming_company_form24: 'Company official website/store website',
  incoming_company_form25: 'Shareholding structure diagram',
  incoming_company_form26: 'Annual return',
  incoming_company_form27: 'Incorporation form',

  incoming_company_upload1: 'Click to upload',
  incoming_company_upload_tip1: 'Business License Picture',
  incoming_company_upload_tip2: 'Pictures of corporate documents',
  incoming_company_upload_tip3:
    'Please confirm information without error and uploaded picture is clear, picture supports jpg, png and jpeg and file size does not exceed 5MB',
  incoming_company_upload_tip4: 'Certificate of Incorporation Picture',
  incoming_company_upload_tip5: 'Picture of Business Registration Certificate',
  incoming_company_upload_tip6:
    'Only images/pdf files can be uploaded and do not exceed 15MB.',
  incoming_company_select_placeholder: 'please select',
  incoming_company_input_placeholder: 'Please enter content',
  incoming_company_date_placeholder1: 'To',
  incoming_company_date_placeholder2: 'Start date',
  incoming_company_date_placeholder3: 'End date',

  incoming_director_title1: "Legal representative's",
  incoming_director_title2: 'Board member',
  incoming_director_title_end: 'information (required)',

  incoming_director_form1: 'Certificate type',
  incoming_director_form2: 'The second generation resident ID card',
  incoming_director_form3: 'Passport',
  incoming_director_form4: 'Hong Kong Permanent Identity Card',
  incoming_director_form5: "Picture of legal representative's certificate",
  incoming_director_form6: "Pictures of Directors' Documents",
  incoming_director_form7: 'portrait side of an ID card',
  incoming_director_form8: 'National emblem side of ID card',
  incoming_director_form9: 'Country and territory of issuance of documents',
  incoming_director_form10: 'Passport front page image',
  incoming_director_form11: 'Name of enterprise legal person',
  incoming_director_form12: "Director's name",
  incoming_director_form13: 'English name of enterprise legal person',
  incoming_director_form14: "Director's English name",
  incoming_director_form15: 'Certificate number',
  incoming_director_form16: 'Gender',
  incoming_director_form17: 'Male',
  incoming_director_form18: 'Female',
  incoming_director_form19: 'Date of birth',
  incoming_director_form20: 'Valid period of certificate',
  incoming_director_form21: 'Non-long-term effective',
  incoming_director_form22: 'Long-term effective',
  incoming_director_form23: 'Legal person address',
  incoming_director_form24: 'Address',
  incoming_director_form25: 'Address certificate',

  incoming_beneficiary_title: "Beneficiary's information (required)",
  incoming_beneficiary_form1: 'Legal person is the unique beneficiary',
  incoming_beneficiary_form2: 'Legal person is not the unique beneficiary',
  incoming_beneficiary_form3: "Beneficiary's ",
  incoming_beneficiary_form4: 'Add',
  incoming_beneficiary_form5: 'Delete',
  incoming_beneficiary_form6: 'Type of beneficiary document',
  incoming_beneficiary_form7: 'The second generation resident ID card',
  incoming_beneficiary_form8: 'Passport',
  incoming_beneficiary_form9: 'Hong Kong Permanent Identity Card',
  incoming_beneficiary_form10: "Pictures of beneficiaries' documents",
  incoming_beneficiary_form11: 'Please upload',
  incoming_beneficiary_form12: 'Pictures of ID card photo pages',
  incoming_beneficiary_form13: 'Name of beneficiary',
  incoming_beneficiary_form14: 'Name of beneficiary in English',
  incoming_beneficiary_form15: 'Please keep one',
  incoming_beneficiary_form16: 'There are 10 beneficiaries at most',

  incoming_agent_title: "Agent's information (optional)",
  incoming_agent_form1: 'Relationship between registrant and enterprise',
  incoming_agent_form2: 'Agent',
  incoming_agent_form3: 'Legal person/director',
  incoming_agent_form4: "Agent's certificate type",
  incoming_agent_form5: "Agent's certificate picture",
  incoming_agent_form6: 'picture of hand-held ID card',
  incoming_agent_form7: "Agent's name",
  incoming_agent_form8: "Agent's English name",
  incoming_agent_form9: "Agent's certificate address",
  incoming_agent_form10: "Agent's letter of authorization of enterprise",

  incoming_button1: 'Temporary storage',
  incoming_button2: 'Submit',
  incoming_message1:
    'Your network access and file submission information is under review',
  incoming_message2:
    'We will complete review within 1-2 working days. After audit, system will send mail notice to your bound mailbox',
  incoming_message3:
    'Network access and file submission information has passed review',
  incoming_message4: 'Sign agreement',
  incoming_message5: 'Your entry information has not been approved.',
  incoming_message6:
    'Please go to the submission page of network access and file submission to modify data and submit the data for review again',
  incoming_message7: 'Go to modify',

  /*----------------未进件页面结束(incoming-unit)------------------------*/

  /*----------------首页菜单------------------------*/

  index_menu1: 'Home page',
  index_menu2: 'My account',
  index_menu3: 'Trading FX',
  index_menu4: 'Global payment',
  index_menu5: 'Currency holiday',
  index_menu6: 'System settings',
  index_menu7: '资金入境',
  index_message: 'Modified successfully',
  index_message2: 'Hello',
  index_message3: 'Modify password',
  index_message4: 'Log off',
  /*----------------首页菜单结束------------------------*/

  /*----------------首页页面------------------------*/

  home_page_title1: 'Account balance',
  home_page_title2: 'Deadline',
  home_page_title3: 'View all accounts',
  home_page_title4: 'My account',
  home_page_title5: 'Currency',
  home_page_title6: 'Balance',
  home_page_title7: 'Account status',
  home_page_title8: 'Operation',
  home_page_title9: 'View details',
  home_page_title10: 'Withdraw deposit',
  home_page_title11: 'Line of credit',
  home_page_title12: 'Details',
  home_page_title13: 'Total credit line',
  home_page_title14: 'Credit line occupied',
  home_page_title15: 'Remaining credit line',
  home_page_title16: 'Recent exchange records',
  home_page_title17: 'Transaction time',
  home_page_title18: 'Currency pair',
  home_page_title19: 'Selling information',
  home_page_title20: 'Buying information',
  home_page_title21: 'Exchange rate',
  home_page_title22: 'Delivery status',
  home_page_title23: 'Backlog',
  home_page_title24: 'Quantity to be delivered',
  home_page_title25: 'Number of overdue payment',
  home_page_title26: 'Currency holiday information',
  home_page_title27: 'I see',
  home_page_title28:
    'Please transfer the recharge amount to the following bank account, please fill in the transaction note accurately when transferring funds.',
  home_page_title29: 'Bank name',
  home_page_title30: 'Bank address',
  home_page_title31: 'Name of receiving account',
  home_page_title32: 'Address of receiving account',
  home_page_title33: 'Receiving account number',
  home_page_title34: 'Postscript',
  home_page_title35: 'Hong Kong local remittance commission',
  home_page_title36:
    'CNH, HKD, USD free of charge, remaining currencies HK$100 or equivalent in foreign currency',
  home_page_title37: 'Cross-border remittance commission',
  home_page_title38:
    'HK100 or equivalent in foreign currency (including remittance from Mainland China)',
  /*----------------首页页面结束------------------------*/

  /*----------------我的币种页面------------------------*/
  my_account_msg1: 'Lexianghui multi-currency account',
  my_account_msg2: 'Available balance',
  my_account_msg3: 'Restricted balance',
  /*----------------我的币种页面结束------------------------*/

  /*----------------credit-record-detail pages------------------------*/

  credit_record_detail_msg1: 'Details of occupied trading quota',
  credit_record_detail_msg2: 'Serial number',
  credit_record_detail_msg3: 'Transaction time',
  credit_record_detail_msg4: 'Sell',
  credit_record_detail_msg5: 'Buy',
  credit_record_detail_msg6: 'Delivery date',
  credit_record_detail_msg7: 'Transaction price',
  credit_record_detail_msg8: 'Contract status',
  credit_record_detail_msg9: 'Undelivered',
  credit_record_detail_msg10: 'Delivered',
  credit_record_detail_msg11: 'Delivery failure',
  credit_record_detail_msg12: 'Breach of contract',

  /*----------------credit-record-detail pages end------------------------*/

  /*----------------check-detail pages------------------------*/

  check_detail_msg1: 'Account details',
  check_detail_msg2: 'Date',
  check_detail_msg3: 'Query',
  check_detail_msg4: 'Downloading',
  check_detail_msg5: 'Time of account',
  check_detail_msg6: 'Income',
  check_detail_msg7: 'Expenditure',
  check_detail_msg8: 'Opening balance',
  check_detail_msg9: 'Transaction description',
  check_detail_msg10: 'Remark',
  check_detail_msg11: 'Download Successfully',

  /*----------------check-detail pages end------------------------*/

  /*----------------system-settings pages------------------------*/

  system_settings_btn: 'Personnel management',
  system_settings_btn2: 'Development settings',
  system_settings_btn3: 'Add user',
  system_settings_text1: 'Administrator',
  system_settings_text2: 'Trader',
  system_settings_text3: 'Inquiry clerk',
  system_settings_text4: 'Normal',
  system_settings_text5: 'Freeze',
  system_settings_text6: 'Editing',
  system_settings_text7: 'Out of service',
  system_settings_text8: 'Start using',
  system_settings_text9: 'Reset passwords',
  system_settings_text10: 'Editorial user',
  system_settings_text11: 'Add user',
  system_settings_text12: 'Name',
  system_settings_text13: 'Role',
  system_settings_text14: 'Administrator',
  system_settings_text15: 'Cancel',
  system_settings_text16: 'Determine',
  system_settings_text17: 'Confirm resetting the current user password?',
  system_settings_text18: 'Deactivate account',
  system_settings_text19: 'Enabling Accounts',
  system_settings_text20: 'Confirm deactivating the current user?',
  system_settings_text21: 'Are you sure you want to enable the current user?',
  system_settings_text22: 'Status',
  /*----------------system-settings pages end------------------------*/

  /*----------------exchange-service pages------------------------*/

  exchange_service_tab1: 'Exchange rate query',
  exchange_service_tab2: 'Initiate exchange',
  exchange_service_tab3: 'Contract query',
  exchange_service_tab4: 'Service fee query',

  /*----------------exchange-service pages end------------------------*/

  /*----------------price-inquiry pages------------------------*/

  price_inquiry_text1: 'Exchange rate query',
  price_inquiry_text2: 'Batch query',
  price_inquiry_text3: 'Currency and delivery date',
  price_inquiry_text4: 'Price query',

  /*----------------price-inquiry pages end------------------------*/

  /*----------------initlate-exchange pages------------------------*/

  initlate_exchange_text1:
    'Spot exchange to support 7*24 hours real-time exchange',
  initlate_exchange_text2: 'Currency and delivery date',
  initlate_exchange_text3: 'Reference rate',
  initlate_exchange_text4: 'Transaction method',
  initlate_exchange_text5: 'Spot exchange',
  initlate_exchange_text6: 'Credit（Available credit：USD',
  initlate_exchange_text7: 'Amount',
  initlate_exchange_text8: 'Next',
  initlate_exchange_text9: 'Confirmation of offer',
  initlate_exchange_text10: 'Confirmation of exchange',
  initlate_exchange_text11: 'Current use of transaction amount',
  initlate_exchange_text12: '剩余交易额度',
  initlate_exchange_text13: 'Working day',
  initlate_exchange_text14: 'Please enter the amount',
  initlate_exchange_text15: 'Your price has expired, please get it again!',
  initlate_exchange_text16: 'Transaction Number',
  initlate_exchange_text17: 'Service fee',
  initlate_exchange_text18: 'System flow number',

  /*----------------initlate-exchange pages end------------------------*/

  /*----------------contract-query pages------------------------*/

  contract_query_title1: 'All',
  contract_query_title2: 'Delivery date',
  contract_query_title3: 'Export data',
  contract_query_title4: 'Product type',
  contract_query_title5: 'Creditworthiness',
  contract_query_title6: 'Currency pair',

  /*----------------contract-query pages end------------------------*/

  /*----------------service-fee-query pages------------------------*/

  service_fee_query_title1: 'Payment status',
  service_fee_query_title2: 'To be paid',
  service_fee_query_title3: 'Paid',
  service_fee_query_title4: 'Reduced and exempted',
  service_fee_query_title5: 'Customer company name',
  service_fee_query_title6: 'Transaction type',
  service_fee_query_title7: 'Type',
  service_fee_query_title8: 'Overdue fine',
  service_fee_query_title9: 'Penalty',
  service_fee_query_title10: 'Fee',
  service_fee_query_title11: 'Time of incurring costs',

  /*----------------service-fee-query pages end------------------------*/

  /*----------------withdraw pages------------------------*/

  withdraw_tab1: 'Initiate cash withdrawal',
  withdraw_tab2: 'Initiate payment',
  withdraw_tab3: 'Cash withdrawal record',
  withdraw_tab4: 'Add receiving account',
  withdraw_title1: 'Account information',
  withdraw_title2: 'Withdraw cash to',
  withdraw_title3: 'Bank account name',
  withdraw_title4: 'Bank account',
  withdraw_title5: "Payee's address",
  withdraw_title6: 'Withdrawal Information',
  withdraw_title7: 'Withdrawal currency',
  withdraw_title8: 'Currency account balance',
  withdraw_title9: 'Cash withdrawal amount',
  withdraw_title10: 'Commission bearing method',
  withdraw_title11: 'Commission currency',
  withdraw_title12: 'Commission currency balance',
  withdraw_title13: 'Commission',
  withdraw_title14: 'Remittance purpose',
  withdraw_title15: 'General commercial purpose',
  withdraw_title16: 'Transaction postscript',
  withdraw_title17: 'Customized payer',
  withdraw_title18: 'Please select payment name',

  /*----------------withdraw pages end------------------------*/

  /*---------------- do-payment pages------------------------*/

  do_payment_title1: 'Account information',
  do_payment_title2: 'Please select payee',
  do_payment_title3: 'Transaction material',
  do_payment_title4:
    'Please upload the invoice or order and relevant evidentiary documents related to the payment',
  do_payment_title5: 'Trading Information',
  do_payment_title6: 'Withdrawal water number',
  do_payment_title7: 'Payment Information',
  do_payment_title8: 'Payer',
  do_payment_title9: 'Account Number',
  do_payment_title10: 'Payment Account Number',
  do_payment_title11: 'Payee',
  do_payment_title12: 'Amount of handling fee',
  do_payment_title13: 'Payee bank',
  do_payment_title14: 'Name of intermediary bank',
  do_payment_title15: 'Middle row',
  do_payment_title16: 'Only one file can be uploaded',
  do_payment_title17: 'Inquiry in progress, please wait',
  do_payment_title18: 'Upload failed, please try again later',
  do_payment_title19: 'Withdrawal request successful',

  /*----------------do-payment pages end------------------------*/

  /*---------------- withdraw-record pages------------------------*/
  withdraw_record_title1: 'Serial number',
  withdraw_record_title2: 'Currency',
  withdraw_record_title3: 'Account number',
  withdraw_record_title4: 'Time',
  withdraw_record_title5: 'Transaction result',
  withdraw_record_title6: 'To be reviewed',
  withdraw_record_title7: 'In progress',
  withdraw_record_title8: 'Have succeeded',
  withdraw_record_title9: 'Failed',
  withdraw_record_title10: 'Download Reply Slip',

  /*----------------withdraw-record pages end------------------------*/

  /*---------------- bind-account pages------------------------*/

  bind_account_title1: 'Cash withdrawal account',
  bind_account_title2: 'Add a withdrawal account with the same name',
  bind_account_title3: 'Add a new receiving account',
  bind_account_title4: 'Account name',
  bind_account_title5: 'Account number',
  bind_account_title6: 'Account type',
  bind_account_title7: 'Payment account',
  bind_account_title8: 'Opening bank',
  bind_account_title9: 'Branch',
  bind_account_title10: 'Area',
  bind_account_title11: 'Nickname',
  bind_account_title12: 'Have passed',
  bind_account_title13: 'Rejected',
  bind_account_title14: 'Are you sure you want to delete it?',
  bind_account_title15: 'Add',
  bind_account_title16: 'bank account information',
  bind_account_title17: 'receiving',
  bind_account_title18: 'Please enter the',
  bind_account_title19:
    'bank information to ensure accurate processing of your withdrawal',
  bind_account_title20: 'Bank account number',
  bind_account_title21: 'Address of opening bank',
  bind_account_title22: "Payee's basic information",
  bind_account_title23:
    "Please enter payee's basic information to ensure accurate processing of your cash withdrawal",
  bind_account_title24: 'Company',
  bind_account_title25: "Payee's name",
  bind_account_title26: "Payee's country or region",
  bind_account_title27: 'Detailed address',
  bind_account_title28: 'Account nickname',
  bind_account_title29: 'Permit of opening a bank account',
  bind_account_title30: 'Contract',
  bind_account_title31: 'Return to account list',
  bind_account_title32: 'Please wait for review',
  bind_account_title33: 'We bear (OUR)',
  bind_account_title34: 'Jointly bear (SHA)',
  bind_account_title35: 'Borne by payee (BEN)',
  /*----------------bind-account pages end------------------------*/

  /* 补齐漏掉的 */
  other_title1: 'Mainland China',
  other_title2: 'Hong Kong, China',
  other_title3: 'Other',
  other_title4: 'Deadline',
  other_title5: 'System Settings',
};
