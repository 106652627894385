// 下载文件统一处理
const contentType = [
  'application/x-msdownload',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/octet-stream',
  'application/vnd.ms-excel;charset=utf8',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf8',
  'application/msexcel;charset=utf-8',
  'application/pdf;charset=utf-8',
  'application/x-download',
];

// @ts-ignore
const download = res => {
  const content = res.data;
  const contentDisposition = res.headers['content-disposition'];
  const blob = new Blob([content]);

  let fileName = '';
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename\*=UTF-8''(.+)$/);
    if (fileNameMatch && fileNameMatch.length === 2) {
      // 如果 filename* 存在，且编码为 UTF-8
      fileName = decodeURIComponent(fileNameMatch[1]);
    } else {
      // 处理常规 filename
      const fileNameIndex = contentDisposition.indexOf('filename=');
      if (fileNameIndex !== -1) {
        fileName = contentDisposition
          .substring(fileNameIndex + 9)
          .replace(/"/g, '');
        fileName = decodeURIComponent(escape(fileName)); // 解码处理
      }
    }
  }

  if ('download' in document.createElement('a')) {
    // 非IE下载
    const eLink = document.createElement('a');
    eLink.download = fileName;
    eLink.style.display = 'none';
    eLink.href = URL.createObjectURL(blob);
    document.body.appendChild(eLink);
    eLink.click();
    URL.revokeObjectURL(eLink.href); // 释放URL 对象
    document.body.removeChild(eLink);
  } else {
    // IE10+下载
    // @ts-ignore
    navigator.msSaveBlob(blob, fileName);
  }
};

// @ts-ignore
function downloadDocument(response) {
  const { headers } = response;
  if (headers && contentType.includes(headers['content-type'])) {
    download(response);
    return false;
  } else {
    return true;
  }
}

export default downloadDocument;
